import { Button } from '@mui/material';
import useAsync from 'hooks/useAsync';
import PropTypes from 'prop-types';
import { auth } from '../firebase.js';

export default function LogOutButton({ children = null, sx = {}, ...rest }) {
    LogOutButton.propTypes = {
        children: PropTypes.node,
        sx: PropTypes.object,
    };
    const requestToLogout = useAsync(async () => {
        // Redirect will be handled by onIdTokenChanged listener in router
        await auth.signOut();
    });

    return (
        <Button
            variant="outlined"
            color="inherit"
            sx={{
                'fontWeight': 600,
                '&:hover': {
                    backgroundColor: 'grey.500',
                    borderColor: 'grey.500',
                },
                ...sx,
            }}
            {...rest}
            disabled={requestToLogout.isRunning}
            onClick={() => requestToLogout.perform()}
        >
            {children ?? 'Log out'}
        </Button>
    );
}
