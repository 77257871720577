import UpdateIcon from '@mui/icons-material/Update';
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useState } from 'react';
import { Link, NavLink, useRouteLoaderData } from 'react-router-dom';

import { FirstPage, LastPage } from '@mui/icons-material';
import PropTypes from 'prop-types';
import LogOutButton from './LogOutButton';

export default memo(AdminLayout);

function AdminLayout({ children }) {
    AdminLayout.propTypes = {
        children: PropTypes.node.isRequired,
    };

    const theme = useTheme();
    const { loggedInUser } = useRouteLoaderData('admin');
    const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] = useState(true);

    const navItems = [{ to: '/admin/sync-schedule', label: 'Sync Schedule', icon: <UpdateIcon /> }];

    return (
        <Box sx={{ height: '100vh', display: 'flex', backgroundColor: theme.palette.primary.light }}>
            {/* Sidebar */}
            <Box
                component="nav"
                aria-label="Admin navigation"
                sx={{
                    minWidth: isSidebarDrawerOpen ? 250 : 58,
                    bgcolor: 'primary.main',
                    color: 'primary.light',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: isSidebarDrawerOpen ? 3 : 1,
                        py: 3,
                    }}
                >
                    {isSidebarDrawerOpen && (
                        <Typography variant="h2" color="inherit" sx={{ m: 0 }}>
                            Admin Hub
                        </Typography>
                    )}
                    <IconButton
                        color="inherit"
                        onClick={() => setIsSidebarDrawerOpen(!isSidebarDrawerOpen)}
                        aria-label="Toggle sidebar drawer"
                    >
                        {isSidebarDrawerOpen ? <FirstPage /> : <LastPage />}
                    </IconButton>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <List sx={{ flexGrow: 1 }}>
                    {navItems?.map((navItem, idx) => (
                        <ListItem key={idx}>
                            <ListItemButton
                                component={NavLink}
                                to={navItem.to}
                                style={({ isActive }) => (isActive ? { textDecoration: 'underline' } : null)}
                                sx={{
                                    'px': isSidebarDrawerOpen ? 3 : 2,
                                    'color': 'inherit',
                                    '&:hover': { backgroundColor: 'grey.400' },
                                }}
                            >
                                <ListItemIcon sx={{ my: 'auto', color: 'inherit' }}>{navItem.icon}</ListItemIcon>
                                {isSidebarDrawerOpen && (
                                    <ListItemText
                                        primary={
                                            <Typography variant={'h4'} color="inherit">
                                                {navItem.label}
                                            </Typography>
                                        }
                                    />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                {isSidebarDrawerOpen && (
                    <>
                        <Button
                            variant="contained"
                            color="inherit"
                            component={Link}
                            to="/projects"
                            sx={{
                                'mx': 3,
                                'display': 'flex',
                                'alignItems': 'center',
                                'fontWeight': 600,
                                'color': 'primary.main',
                                'backgroundColor': 'primary.light',
                                '&:hover': {
                                    backgroundColor: 'grey.300',
                                },
                            }}
                        >
                            Go to GCP Projects View
                        </Button>
                        <LogOutButton sx={{ m: 3 }}>Log out {loggedInUser?.displayName}</LogOutButton>
                    </>
                )}
            </Box>

            {/* Main content */}
            <Box
                component="main"
                sx={{
                    p: 3,
                    px: {
                        xs: 2,
                        md: 4,
                    },
                    overflowY: 'auto',
                    flexGrow: 1,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
