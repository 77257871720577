import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useLoaderData } from 'react-router-dom';

import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import PageHeader from 'components/PageHeader';

export default function ManagedSyncsIndexPage() {
    const { syncs } = useLoaderData();

    const doesProjectHaveSyncs = Boolean(syncs?.length);

    return (
        <>
            <PageHeader title="Managed Syncs" subtitle="Syncs managed and created by CTA for this project" />
            <section>
                {doesProjectHaveSyncs ? (
                    <TableContainer
                        component={Paper}
                        sx={{ overflowX: 'auto', border: 1, borderColor: 'grey.300', borderBottom: 0 }}
                        square
                    >
                        <Table sx={{ minWidth: 650 }} aria-label="Syncs table">
                            <TableBody>
                                {syncs.map((sync) => (
                                    <TableRow key={sync.id}>
                                        <TableCell>{sync.id}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <EmptyStatePlaceholder
                        title="No syncs to show"
                        subtitle="CTA-managed syncs will appear here after they are set up"
                    />
                )}
            </section>
        </>
    );
}
