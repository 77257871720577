import { Navigate, useRouteLoaderData } from 'react-router-dom';

export default function AdminSuperUserGuard({ children }) {
    const { loggedInUser = {} } = useRouteLoaderData('admin');

    const isAccessGranted = loggedInUser?.claims?.isSuperUser;

    if (!loggedInUser) {
        return <Navigate to="/login" />;
    }

    if (!isAccessGranted) {
        return <Navigate to="/projects" />;
    }

    return children;
}
