import PropTypes from 'prop-types';
import { Navigate, Outlet, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom';

// Project imports
import { PROJECT_FEATURE_FLAGS } from '@cta-pond/constants';
import useFeatureFlags from 'hooks/useFeatureFlags';

/**
 * @todo: lift these checks to the route loaders
 * A component that renders an Outlet (or children) if the included feature flag key is enabled
 * Also renders if the user is a super user and allowSuperUsers is true
 * Otherwise, redirects to a default route per the feature flags
 */
export default function FeatureGuard({ children = null, featureFlagKey, allowSuperUsers = true }) {
    FeatureGuard.propTypes = {
        featureFlagKey: PropTypes.string.isRequired,
        children: PropTypes.node,
        allowSuperUsers: PropTypes.bool,
    };

    const featureFlags = useFeatureFlags();
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const { loggedInUser } = useRouteLoaderData('projects');

    const isAccessGranted = featureFlags?.[featureFlagKey] || (loggedInUser?.claims?.isSuperUser && allowSuperUsers);

    if (isAccessGranted && children) {
        return children;
    }

    if (isAccessGranted) {
        return <Outlet />;
    }

    const maybeSearchParams = searchParams?.size ? `?${searchParams.toString()}` : '';

    let redirectRoute = '';

    if (featureFlags?.[PROJECT_FEATURE_FLAGS.IS_MANAGED_SYNCS_PAGE_ENABLED]) {
        redirectRoute = '/syncs/managed';
    } else if (
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED] ||
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED]
    ) {
        redirectRoute = '/matching-jobs';
    } else if (featureFlags?.[PROJECT_FEATURE_FLAGS.IS_VAN_RETL_ENABLED]) {
        redirectRoute = '/retl-jobs';
    } else if (featureFlags) {
        // Feature flags are loaded, but no feature flags / superuser enabled
        redirectRoute = '/welcome';
    }

    const redirectUrl = `/projects/${projectId}${redirectRoute}${maybeSearchParams}`;

    return <Navigate to={redirectUrl} />;
}
