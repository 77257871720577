import { SYNC_RUN_STATUS_LABELS, SYNC_RUN_STATUSES } from '@cta-pond/constants';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

export default function SyncRunStatus({ status }) {
    SyncRunStatus.propTypes = {
        status: PropTypes.oneOf(Object.values(SYNC_RUN_STATUSES)).isRequired,
    };

    const statusBadgeColors = {
        [SYNC_RUN_STATUSES.SUCCEEDED]: 'success',
        [SYNC_RUN_STATUSES.FAILED]: 'error',
    };

    return (
        <Chip
            variant="outlined"
            label={SYNC_RUN_STATUS_LABELS[status] ?? 'Unknown'}
            color={statusBadgeColors[status] ?? 'default'}
            size="small"
            sx={{ fontWeight: 600 }}
        />
    );
}
