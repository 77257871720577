// Material-ui
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useRouteLoaderData } from 'react-router-dom';

// Project imports
import PreviewLabel from 'components/PreviewLabel';
import useLayoutState from 'hooks/useLayoutState';
import LogOutButton from './LogOutButton';

export default function Header() {
    const theme = useTheme();
    const { isSidebarDrawerOpen, toggleIsSidebarDrawerOpen } = useLayoutState();
    const { loggedInUser } = useRouteLoaderData('projects');

    return (
        <AppBar
            enableColorOnDark
            position="fixed"
            elevation={0}
            sx={{
                bgcolor: 'primary.main',
                color: 'primary.light',
                transition: isSidebarDrawerOpen ? theme.transitions.create('width') : 'none',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: { xs: 'column', md: 'row' },
                    py: '12px',
                }}
            >
                {/* logo & toggler button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto',
                            marginBottom: 2,
                        },
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => toggleIsSidebarDrawerOpen()}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <Typography variant="h2" color="inherit" sx={{ fontWeight: 600 }}>
                                    PAD UI
                                </Typography>
                                <PreviewLabel />
                            </Box>

                            <Typography variant="h5" color="inherit" sx={{ fontWeight: 500 }}>
                                From Community Tech Alliance
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
                    <Typography color="inherit" sx={{ mr: 1 }}>
                        Logged in as {loggedInUser?.displayName}
                    </Typography>
                    {loggedInUser?.claims?.isSuperUser && (
                        <>
                            <AdminPanelSettingsIcon />
                            <Typography variant="h5" color="inherit" sx={{ fontWeight: 600, mr: 2 }}>
                                Super User
                            </Typography>
                            <Button
                                variant="contained"
                                color="inherit"
                                component={Link}
                                to="/admin/sync-schedule"
                                sx={{
                                    'mr': 2,
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'fontWeight': 600,
                                    'color': 'primary.main',
                                    'backgroundColor': 'primary.light',
                                    '&:hover': {
                                        backgroundColor: 'grey.300',
                                    },
                                }}
                            >
                                Go to Admin Hub
                            </Button>
                        </>
                    )}
                    <LogOutButton />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
