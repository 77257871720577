import { QUERY_PARAMS } from '@cta-pond/constants';
import {
    Alert,
    Autocomplete,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import PageHeader from 'components/PageHeader';
import SyncRunStatus from 'components/SyncRunStatus';
import cronstrue from 'cronstrue';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';
import { useState } from 'react';
import { useLoaderData, useNavigation, useSearchParams } from 'react-router-dom';

function getCronDescription(scheduleInterval, tzOffset = 0) {
    if (scheduleInterval) {
        try {
            tzOffset = 0 - tzOffset;
            let description = cronstrue.toString(scheduleInterval, { verbose: true, tzOffset });
            if (description.startsWith('At ')) {
                description = description.replace('At ', '');
            }
            return description;
        } catch (err) {
            return 'Invalid cron expression';
        }
    } else {
        return 'No schedule provided';
    }
}

export default function SyncSchedulePage() {
    const { syncs, allPartnerIds, allVendorNames } = useLoaderData();
    const [searchParams, setSearchParams] = useSearchParams();
    const { state } = useNavigation();
    const [isTzAlertVisible, setIsTzAlertVisible] = useState(true);

    const browserNow = new Date();
    const tzOffsetInHours = browserNow.getTimezoneOffset() / 60;
    const currentTzAcronym = browserNow.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
    const currentTzVerbose = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const onChangeFilter = (filterType) => (event, value) => {
        const newValues = value ? { [filterType]: value } : {};
        setSearchParams(newValues);
    };

    const onChangePartnerFilter = onChangeFilter(QUERY_PARAMS.PARTNER_ID);
    const onChangeSourceFilter = onChangeFilter(QUERY_PARAMS.SOURCE_ID);

    const stickyCellStyles = {
        position: 'sticky',
        left: 0,
        minWidth: 250,
        backgroundColor: 'white',
        borderRight: '2px solid',
        borderRightColor: 'grey.300',
    };

    const headerCellStyles = {
        minWidth: 200,
        zIndex: 1,
    };

    return (
        <>
            <PageHeader title="Sync Schedule" subtitle="View sync details across partners and platforms" />
            <section>
                {isTzAlertVisible && (
                    <Alert
                        square
                        severity="info"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        onClose={() => setIsTzAlertVisible(false)}
                    >
                        All times are displayed in your local timezone: {currentTzAcronym} ({currentTzVerbose})
                    </Alert>
                )}
                <LinearProgress sx={{ opacity: state === 'loading' ? 1 : 0 }} />
                <TableContainer
                    sx={{
                        maxHeight: isTzAlertVisible ? '77vh' : '85vh',
                        border: 1,
                        borderColor: 'grey.300',
                        backgroundImage: `
                            linear-gradient(to right, white, white),
                            linear-gradient(to right, white, white),
                            linear-gradient(to right, rgba(0,0,0,0.3), rgba(255,255,255,0)),
                            linear-gradient(to left, rgba(0,0,0,0.3), rgba(255,255,255,0))
                        `,
                        backgroundPosition: 'left center, right center, left center, right center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'white',
                        backgroundSize: '20px 100%, 20px 100%, 10px 100%, 25px 100%',
                        backgroundAttachment: 'local, local, scroll, scroll',
                    }}
                >
                    <Table aria-label="Syncs table" size="small" stickyHeader sx={{ whiteSpace: 'nowrap' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={stickyCellStyles}>
                                    <Autocomplete
                                        id="partner-filter"
                                        value={searchParams.get(QUERY_PARAMS.PARTNER_ID)}
                                        onChange={onChangePartnerFilter}
                                        options={[...allPartnerIds].sort((a, b) => a.localeCompare(b))} // Sort alphabetically
                                        fullWidth={true}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Partner ID" />
                                        )}
                                    />
                                </TableCell>
                                <TableCell sx={stickyCellStyles}>
                                    <Autocomplete
                                        id="source-filter"
                                        value={searchParams.get(QUERY_PARAMS.SOURCE_ID)}
                                        onChange={onChangeSourceFilter}
                                        options={[...allVendorNames].sort((a, b) => a.localeCompare(b))}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} size="small" label="Source" />}
                                    />
                                </TableCell>
                                <TableCell sx={headerCellStyles}>Schedule</TableCell>
                                <TableCell sx={headerCellStyles}>Last run status</TableCell>
                                <TableCell sx={headerCellStyles}>Last run end</TableCell>
                                <TableCell sx={headerCellStyles}>Next run</TableCell>
                                <TableCell sx={{ ...headerCellStyles, minWidth: 400 }}>Sync ID</TableCell>
                                <TableCell sx={headerCellStyles}>Tags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {syncs.map((sync) => (
                                <TableRow key={sync.id}>
                                    <TableCell sx={stickyCellStyles}>{sync.partnerName ?? '-'}</TableCell>
                                    <TableCell sx={stickyCellStyles}>{sync.vendorName ?? '-'}</TableCell>

                                    <TableCell>{getCronDescription(sync.scheduleInterval, tzOffsetInHours)}</TableCell>
                                    <TableCell>
                                        <SyncRunStatus status={sync.lastState} />
                                    </TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.lastEndDate)}</TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.nextDAGRun)}</TableCell>
                                    <TableCell>{sync.id}</TableCell>
                                    <TableCell>
                                        <Typography noWrap>
                                            {sync.tags?.map((tag) => tag.name)?.join(', ') ?? '-'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </>
    );
}
